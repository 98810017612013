import LeftBox from "./LeftBox";
import RightBox from "./RightBox";

function Footer() {
  return (
    <footer>
      <div className="footer">
        <div className="footer__left" />
        <div className="footer__right" />
        <div className="container">
          <div className="content_box py-5">
            <LeftBox />
            <RightBox />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="footer_copyright_and_links">
          <div className="footer__bottom__content">
            <ul className="policy_links list-unstyled">
              <li>
                <a
                  class=" footer_link"
                  href="https://www.outmazedtourism.com/terms-and-conditions/"
                >
                  Terms and Conditions
                </a>
              </li>
              <li>
                <a
                  class=" footer_link"
                  rel="privacy-policy"
                  href="https://www.outmazedtourism.com/privacy-policy/"
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  class=" footer_link"
                  href="https://www.outmazedtourism.com/cookies-policy/"
                >
                  Cookies Policy
                </a>
              </li>
              <li>
                <a
                  class=" footer_link"
                  href="https://www.outmazedtourism.com/sitemap_index.xml"
                >
                  Sitemap
                </a>
              </li>
              <li>
                <a
                  class=" footer_link"
                  href="https://www.outmazedtourism.com/contact-us/"
                >
                  Support
                </a>
              </li>
              <p className="mb-0">
                &copy; {new Date().getFullYear()} OutMazed Tourism. All rights
                reserved.
              </p>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

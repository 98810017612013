import React, { useState, useEffect } from "react";
import { Dropdown, Space } from "antd";
import useSubdomains from "../../hooks/useSubdomains";
import Loader from "../../components/Loader";

function NavLinks() {
  const { subdomains } = useSubdomains();
  if (!subdomains) {
    return <Loader />;
  }
  return (
    <ul className="primary_navbar__links_nav">
      <li>
        <a href="https://www.outmazedtourism.com/about-us/">About Us</a>
      </li>
      <li>
        <a href="https://www.outmazedtourism.com/our-services/">Our Services</a>
      </li>
      <li>
        <a href="https://www.outmazedtourism.com/our-brands/">Our Brand</a>
      </li>
      <li>
        <a href="https://www.outmazedtourism.com/career/">Careers</a>
      </li>
      <li>
        <a href="https://www.outmazedtourism.com/contact-us/">Contact Us</a>
      </li>
      <li>
        <a href="https://www.outmazedtourism.com/blog/">Blogs</a>
      </li>
      <li className="special_offer">
        <Dropdown
          menu={{
            items: subdomains,
          }}
        >
          <a href="https://special-offers.outmazedtourism.com">
            <Space>Destinations</Space>
          </a>
        </Dropdown>
      </li>
    </ul>
  );
}
function Social() {
  return (
    <ul className="primary_navbar__links_social">
      <li>
        <a href="https://www.instagram.com/outmazedtourism/">
          <img src="/img/social/insta.svg" alt="" />
        </a>
      </li>
      <li>
        <a href="https://www.facebook.com/OutMazedTourism/">
          <img src="/img/social/facebook.svg" alt="" />
        </a>
      </li>
      <li>
        <a href="https://www.youtube.com/@OutMazedTourism">
          <img src="/img/social/youtube.svg" alt="" />
        </a>
      </li>
      <li>
        <a href="https://www.linkedin.com/company/outmazedtourism/">
          <img src="/img/social/linkedin.svg" alt="" />
        </a>
      </li>
      <li>
        <a href="https://twitter.com/OutMazedT">
          <img src="/img/social/twitter.svg" alt="" />
        </a>
      </li>
      <li>
        <a href="https://www.tiktok.com/@outmazedtourism">
          <img src="/img/social/tiktok.svg" alt="" />
        </a>
      </li>
      <li>
        <a href="https://wa.me/971503431800">
          <img src="/img/social/whatsapp.svg" alt="" />
        </a>
      </li>
    </ul>
  );
}

export default function NavLg() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const checkScrollPosition = () => {
      if (window.scrollY > 50) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Add event listener for scroll
    document.addEventListener("scroll", checkScrollPosition);

    // Check scroll position on page load
    checkScrollPosition();

    // Cleanup function to remove event listener
    return () => {
      document.removeEventListener("scroll", checkScrollPosition);
    };
  }, []);
  return (
    <>
      <div
        className={`primary_navbar__toggler ${isMenuOpen ? "open" : ""}`}
        onClick={toggleMenu}
      >
        <div className={`primary_navbar__toggler_wrapper `}>
          <span />
          <span />
          <span />
          <span />
        </div>
      </div>
      <div className={`mobile_nav ${isMenuOpen ? "open" : ""}`}>
        <NavLinks />
        <Social />
      </div>
      <div
        className={`nav_container ${isMenuOpen ? "open" : ""} ${
          isSticky ? "sticky" : ""
        }`}
      >
        <div className="container">
          <nav className="primary_navbar" id="primary_navbar">
            <a href="/" className="primary_navbar__main_logo">
              <img
                src="/img/outmazed_tourism_logo.svg"
                alt="Outmazed Tourism Logo"
                className="primary_navbar__main_logo_image"
              />
            </a>
            <div className="primary_navbar__links ">
              <NavLinks />

              <Social />
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}

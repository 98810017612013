import { useState, useEffect } from 'react'
import { fetchData } from "../api";

const backend = process.env.REACT_APP_API_URL;
function useSliderData() {

    const [sliderData, setSliderData] = useState(null);
    const [error, setError] = useState(null);
    useEffect(() => {
        const fetchSliderData = async () => {
            try {
                const response = await fetchData(
                    `/api/subdomains/?populate[image][populate][1]=image`
                );
                const data = response.data;


                setSliderData(data.map((slide) => {
                    return transformSlidereData(slide)
                }));


            } catch (error) {
                console.error("Error fetching home data:", error);
                setError(error);
            }
        };

        fetchSliderData();


    }, [backend])
    return { sliderData, error };



}

function transformSlidereData(data) {
    return {
        title: data.attributes.title,
        subTitle: data.attributes.subtitle || "",
        endpoint: data.attributes.endpoint_url || "",
        image: data.attributes.image ? `${backend}${data.attributes.image.data.attributes.url}` : "",
        slug: data.attributes.slug,
    };
}
export default useSliderData
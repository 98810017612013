import { useEffect, useState } from "react";
import { fetchData } from "../api";


function useSubdomains() {
    const [subdomains, setSubdomains] = useState(null);

    /**
     *     {
          key: "1",
          label: (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.antgroup.com"
            >
              1st menu item
            </a>
          ),
        }
     */
    useEffect(() => {
        const fetchSubDomains = async () => {

            try {
                const response = await fetchData(
                    `/api/subdomains/?populate[products]=products&filters[products][publishedAt][$nq]=null`
                );
                setSubdomains(response.data.map((sub, index) => {
                    return {
                        key: String(index),
                        label: (
                            <a
                                target="_self"
                                href={`${sub.attributes.endpoint_url}`}
                            >
                                {sub.attributes.title}
                            </a>
                        ),
                    }
                }));
            } catch (error) {
                console.error("Error fetching home data:", error);
            }
        };

        fetchSubDomains()

    }, []);


    return { subdomains };
}


export default useSubdomains;